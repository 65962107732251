var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { PureComponent } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import Dialog from './dialog';
import { DefaultButton, GreenButton } from './buttons';
var hideOnMobile = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  @media (max-width: 600px) {\n    display: none;\n  }\n"], ["\n  @media (max-width: 600px) {\n    display: none;\n  }\n"])));
var TableScroll = styled('div')(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  overflow-x: auto;\n  margin-top: 16px;\n"], ["\n  overflow-x: auto;\n  margin-top: 16px;\n"])));
var Table = styled('table')(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border-collapse: collapse;\n  font-size: 12px;\n"], ["\n  border-collapse: collapse;\n  font-size: 12px;\n"])));
var ColumnHeading = styled('th')(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background: #f7f8fa;\n  color: #1f4160;\n  font-weight: 600;\n  text-align: left;\n  border-width: 2px;\n"], ["\n  background: #f7f8fa;\n  color: #1f4160;\n  font-weight: 600;\n  text-align: left;\n  border-width: 2px;\n"])));
var RowHeading = styled('th')(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-weight: normal;\n  text-align: left;\n"], ["\n  font-weight: normal;\n  text-align: left;\n"])));
var Row = styled('tr')(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  th,\n  td {\n    vertical-align: top;\n    padding: 8px 12px;\n    border: 1px solid rgba(67, 90, 111, 0.114);\n  }\n  td {\n    border-top: none;\n  }\n"], ["\n  th,\n  td {\n    vertical-align: top;\n    padding: 8px 12px;\n    border: 1px solid rgba(67, 90, 111, 0.114);\n  }\n  td {\n    border-top: none;\n  }\n"])));
var InputCell = styled('td')(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  input {\n    vertical-align: middle;\n  }\n  label {\n    display: block;\n    margin-bottom: 4px;\n    white-space: nowrap;\n  }\n  td {\n    border: none;\n    vertical-align: middle;\n  }\n"], ["\n  input {\n    vertical-align: middle;\n  }\n  label {\n    display: block;\n    margin-bottom: 4px;\n    white-space: nowrap;\n  }\n  td {\n    border: none;\n    vertical-align: middle;\n  }\n"])));
var PreferenceDialog = /** @class */ (function (_super) {
    __extends(PreferenceDialog, _super);
    function PreferenceDialog() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleChange = function (e) {
            var onChange = _this.props.onChange;
            onChange(e.target.name, e.target.value === 'true');
        };
        _this.handleSubmit = function (e) {
            var _a = _this.props, onSave = _a.onSave, preferences = _a.preferences, marketingAndAnalytics = _a.marketingAndAnalytics, advertising = _a.advertising, functional = _a.functional, customCategories = _a.customCategories;
            e.preventDefault();
            // Safe guard against browsers that don't prevent the
            // submission of invalid forms (Safari < 10.1)
            if (!customCategories &&
                (marketingAndAnalytics === null || advertising === null || functional === null)) {
                return;
            }
            // Safe guard against custom categories being null
            if (customCategories &&
                Object.keys(customCategories).some(function (category) { return preferences[category] === null; })) {
                return;
            }
            onSave();
        };
        return _this;
    }
    PreferenceDialog.prototype.render = function () {
        var _this = this;
        var _a = this.props, innerRef = _a.innerRef, onCancel = _a.onCancel, marketingDestinations = _a.marketingDestinations, advertisingDestinations = _a.advertisingDestinations, functionalDestinations = _a.functionalDestinations, marketingAndAnalytics = _a.marketingAndAnalytics, advertising = _a.advertising, functional = _a.functional, customCategories = _a.customCategories, destinations = _a.destinations, title = _a.title, content = _a.content, preferences = _a.preferences, preferencesDialogTemplate = _a.preferencesDialogTemplate;
        var _b = preferencesDialogTemplate, headings = _b.headings, checkboxes = _b.checkboxes, actionButtons = _b.actionButtons;
        var functionalInfo = preferencesDialogTemplate === null || preferencesDialogTemplate === void 0 ? void 0 : preferencesDialogTemplate.categories.find(function (c) { return c.key === 'functional'; });
        var marketingInfo = preferencesDialogTemplate === null || preferencesDialogTemplate === void 0 ? void 0 : preferencesDialogTemplate.categories.find(function (c) { return c.key === 'marketing'; });
        var advertisingInfo = preferencesDialogTemplate === null || preferencesDialogTemplate === void 0 ? void 0 : preferencesDialogTemplate.categories.find(function (c) { return c.key === 'advertising'; });
        var essentialInfo = preferencesDialogTemplate === null || preferencesDialogTemplate === void 0 ? void 0 : preferencesDialogTemplate.categories.find(function (c) { return c.key === 'essential'; });
        var buttons = (React.createElement("div", null,
            React.createElement(DefaultButton, { type: "button", onClick: onCancel }, actionButtons.cancelValue),
            React.createElement(GreenButton, { type: "submit" }, actionButtons.saveValue)));
        return (React.createElement(Dialog, { innerRef: innerRef, title: title, buttons: buttons, onCancel: onCancel, onSubmit: this.handleSubmit },
            content,
            React.createElement(TableScroll, null,
                React.createElement(Table, null,
                    React.createElement("thead", null,
                        React.createElement(Row, null,
                            React.createElement(ColumnHeading, { scope: "col" }, headings.allowValue),
                            React.createElement(ColumnHeading, { scope: "col" }, headings.categoryValue),
                            React.createElement(ColumnHeading, { scope: "col" }, headings.purposeValue),
                            React.createElement(ColumnHeading, { scope: "col", css: hideOnMobile }, headings.toolsValue))),
                    React.createElement("tbody", null,
                        !customCategories && (React.createElement(React.Fragment, null,
                            React.createElement(Row, null,
                                React.createElement(InputCell, null,
                                    React.createElement("label", null,
                                        React.createElement("input", { type: "radio", name: "functional", value: "true", checked: functional === true, onChange: this.handleChange, "aria-label": "Allow functional tracking", required: true }),
                                        ' ',
                                        checkboxes.yesValue),
                                    React.createElement("label", null,
                                        React.createElement("input", { type: "radio", name: "functional", value: "false", checked: functional === false, onChange: this.handleChange, "aria-label": "Disallow functional tracking", required: true }),
                                        ' ',
                                        checkboxes.noValue)),
                                React.createElement(RowHeading, { scope: "row" }, functionalInfo === null || functionalInfo === void 0 ? void 0 : functionalInfo.name),
                                React.createElement("td", null,
                                    React.createElement("p", null, functionalInfo === null || functionalInfo === void 0 ? void 0 : functionalInfo.description),
                                    React.createElement("p", { css: hideOnMobile }, functionalInfo === null || functionalInfo === void 0 ? void 0 : functionalInfo.example)),
                                React.createElement("td", { css: hideOnMobile }, functionalDestinations.map(function (d) { return d.name; }).join(', '))),
                            React.createElement(Row, null,
                                React.createElement(InputCell, null,
                                    React.createElement("label", null,
                                        React.createElement("input", { type: "radio", name: "marketingAndAnalytics", value: "true", checked: marketingAndAnalytics === true, onChange: this.handleChange, "aria-label": "Allow marketing and analytics tracking", required: true }),
                                        ' ',
                                        checkboxes.yesValue),
                                    React.createElement("label", null,
                                        React.createElement("input", { type: "radio", name: "marketingAndAnalytics", value: "false", checked: marketingAndAnalytics === false, onChange: this.handleChange, "aria-label": "Disallow marketing and analytics tracking", required: true }),
                                        ' ',
                                        checkboxes.noValue)),
                                React.createElement(RowHeading, { scope: "row" }, marketingInfo === null || marketingInfo === void 0 ? void 0 : marketingInfo.name),
                                React.createElement("td", null,
                                    React.createElement("p", null, marketingInfo === null || marketingInfo === void 0 ? void 0 : marketingInfo.description),
                                    React.createElement("p", { css: hideOnMobile }, marketingInfo === null || marketingInfo === void 0 ? void 0 : marketingInfo.example)),
                                React.createElement("td", { css: hideOnMobile }, marketingDestinations.map(function (d) { return d.name; }).join(', '))),
                            React.createElement(Row, null,
                                React.createElement(InputCell, null,
                                    React.createElement("label", null,
                                        React.createElement("input", { type: "radio", name: "advertising", value: "true", checked: advertising === true, onChange: this.handleChange, "aria-label": "Allow advertising tracking", required: true }),
                                        ' ',
                                        checkboxes.yesValue),
                                    React.createElement("label", null,
                                        React.createElement("input", { type: "radio", name: "advertising", value: "false", checked: advertising === false, onChange: this.handleChange, "aria-label": "Disallow advertising tracking", required: true }),
                                        ' ',
                                        checkboxes.noValue)),
                                React.createElement(RowHeading, { scope: "row" }, advertisingInfo === null || advertisingInfo === void 0 ? void 0 : advertisingInfo.name),
                                React.createElement("td", null,
                                    React.createElement("p", null, advertisingInfo === null || advertisingInfo === void 0 ? void 0 : advertisingInfo.description),
                                    React.createElement("p", { css: hideOnMobile }, advertisingInfo === null || advertisingInfo === void 0 ? void 0 : advertisingInfo.example)),
                                React.createElement("td", { css: hideOnMobile }, advertisingDestinations.map(function (d) { return d.name; }).join(', '))),
                            React.createElement(Row, null,
                                React.createElement("td", null, "N/A"),
                                React.createElement(RowHeading, { scope: "row" }, essentialInfo === null || essentialInfo === void 0 ? void 0 : essentialInfo.name),
                                React.createElement("td", null,
                                    React.createElement("p", null, essentialInfo === null || essentialInfo === void 0 ? void 0 : essentialInfo.description),
                                    React.createElement("p", null, essentialInfo === null || essentialInfo === void 0 ? void 0 : essentialInfo.example)),
                                React.createElement("td", { css: hideOnMobile })))),
                        customCategories &&
                            Object.entries(customCategories).map(function (_a) {
                                var categoryName = _a[0], _b = _a[1], integrations = _b.integrations, purpose = _b.purpose;
                                return (React.createElement(Row, { key: categoryName },
                                    React.createElement(InputCell, null, preferences[categoryName] === 'N/A' ? (React.createElement("td", null, "N/A")) : (React.createElement(React.Fragment, null,
                                        React.createElement("label", null,
                                            React.createElement("input", { type: "radio", name: categoryName, value: "true", checked: preferences[categoryName] === true, onChange: _this.handleChange, "aria-label": "Allow \"" + categoryName + "\" tracking", required: true }),
                                            ' ',
                                            checkboxes.yesValue),
                                        React.createElement("label", null,
                                            React.createElement("input", { type: "radio", name: categoryName, value: "false", checked: preferences[categoryName] === false, onChange: _this.handleChange, "aria-label": "Disallow \"" + categoryName + "\" tracking", required: true }),
                                            ' ',
                                            checkboxes.noValue)))),
                                    React.createElement(RowHeading, { scope: "row" }, categoryName),
                                    React.createElement("td", null,
                                        React.createElement("p", null, purpose)),
                                    React.createElement("td", { css: hideOnMobile }, destinations
                                        .filter(function (d) { return integrations.includes(d.id); })
                                        .map(function (d) { return d.name; })
                                        .join(', '))));
                            }))))));
    };
    PreferenceDialog.displayName = 'PreferenceDialog';
    PreferenceDialog.defaultProps = {
        marketingAndAnalytics: null,
        advertising: null,
        functional: null
    };
    return PreferenceDialog;
}(PureComponent));
export default PreferenceDialog;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
